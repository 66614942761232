// variable

// font weight
$font-weight: (
    thin: 100,
    extra-light: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
    extra-bold: 800,
    black: 900
);

// font size
$fs: (
    fs-default: 14px,
    fs-heading-large: 30px,
    fs-heading-normal: 20px,
    fs-heading-small: 18px,
    fs-label: 16px,
    fs-input: 13px,
    fs-error: 13px
);

// color
$colors: (
    1: #D1BA7A,
    2: #F5F5F5,
    3: #140808,
    4: #4082D0,
    light-gray: #D5D5D5,
    white: #FFFFFF
);

// height
$height-default: 35px;

// border-radius
$radius-default: 10px;

// shadow
$shadow-box-default: rgba(0, 0, 0, 0.18) 0px 2px 4px;

// custom bootstrap
// dropdowns
$dropdown-link-active-bg: map-get($map: $colors, $key: 3);
// accordion
$accordion-button-color: map-get($map: $colors, $key: 3);
$accordion-button-bg: map-get($map: $colors, $key: white);
$accordion-button-active-bg: map-get($map: $colors, $key: white);
$accordion-button-active-color: map-get($map: $colors, $key: 3);
$accordion-button-focus-border-color: map-get($map: $colors, $key: 3);
$accordion-button-focus-box-shadow: none;
$accordion-icon-color: map-get($map: $colors, $key: 3);
$accordion-icon-active-color: map-get($map: $colors, $key: 3);
$accordion-border-width: 0px;
$accordion-button-padding-y: 20px;

// form floating
$form-floating-label-transform: translateY(-1.25rem);