@use "../../variables" as css;

.wrapper {

    &.wrapper-only-background {
        .wrapper-container {
            width: 100%;
            min-height: 100vh;
            background: linear-gradient(113.49deg, #D1BA7A -30.3%, #111111 58.12%);

            .card-group {
                position: absolute;
                left: 0;
                bottom: 0;
                max-height: 95vh;
                max-width: 95vw;
            }

            .wrapper-content {
                position: absolute;
                width: 100%;
                height: 100%;
                padding: 100px;
                display: flex;
                justify-content: end;

                .wrapper-form {
                    width: 530px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: css.$radius-default;
                    padding: 30px;
                    position: relative;

                    .form-bottom {
                        position: absolute;
                        width: 100%;
                        padding: 0px 30px;
                        left: 0px;
                        bottom: 50px;
                    }
                }
            }
        }
    }

    &.wrapper-body {
        .wrapper-container {
            width: 100%;
            min-height: 100vh;
            background-color: #0E0E10;
            padding: 50px 100px;
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                width: 717px;
                height: 691px;
                left: -256px;
                top: -272px;

                background: #D1BA7A;
                opacity: 0.3;
                filter: blur(200px);
            }

            &::after {
                content: '';
                position: absolute;
                width: 609px;
                height: 307px;
                left: -304px;
                bottom: -400px;
                background: #D1BA7A;
                filter: blur(255px);

            }
            
            * {
                color: white;
            }

            .wrapper-header {
                .header-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .header-nav {
                        display: flex;
                        align-self: flex-end;
                        align-items: center;
                        gap: 100px;

                        a {
                            transition: 0.3s all;
                            &:hover {
                                color: map-get($map: css.$colors, $key: 1) !important;
                            }
                        }
                    }

                    .avatar {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        i {
                            font-size: 20px;
                        }

                        img {
                            width: 42px;
                            height: 42px;
                            border-radius: 100%;
                        }
                    }
                }
            }

            .wrapper-content {
                padding: 100px 0px 150px;
            }

            .wrapper-footer {
                position: absolute;
                left: 0px;
                padding: 0px 100px;
                bottom: 50px;
                width: 100%;

                .footer-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .footer-nav {
                        a {
                            color: map-get($map: css.$colors, $key: 1) !important;
                            transition: 0.3s all;

                            &:hover {
                                color: map-get($map: css.$colors, $key: white) !important;
                            }

                            &::before {
                                content: '|';
                                position: relative;
                                top: -1px;
                                color: map-get($map: css.$colors, $key: 1) !important;
                                padding: 0px 10px;
                            }

                            &:first-child {
                                &::before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .wrapper {

        &.wrapper-only-background {
            .wrapper-container {

                .card-group {
                    display: none;
                }
    
                .wrapper-content {
                    padding: 100px 20px;
                    justify-content: center;
                }
            }
        }
    }
}